.footer-area {
    padding: 3rem 0 2rem 0;
}

.footer-text-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
