.fullscreen {
    .container, article.content {
        max-width: 100%!important;
    }

    .header-area, .breadcrumb, .headline, .icon-menu, .field-box-xl1, .fields-meta, .field-box-sm1-lg4 {
        display: none!important;
    }
    
}
.admin article.content {
    overflow-y: hidden;
    max-width: none;
}
.admin footer {
    display: none;
}
#text-content {
    height: 80vh;
    overflow: scroll;
    .fiche__title{
        display:none;
    } 
    .boutons {
        .bouton--nok, .bouton--enonce, .bouton--explic, .bouton--reponse, .bouton--next {
            display:none;
        }
        .bouton--ok{
            display:block;
        }
    }
    .encadre__intro{
        margin-top:1em;
        border-top: 2px solid #666;
    }
    .encadre__affirm{
        background-color: #fff9f0;
    }
    
    
}

#source {
    height: 80vh;
}