.header-main-wrapper {
    padding: 2.5rem 0;

    a {
        display: flex;
        align-items: center;
        color: $primary;
        border: none;

        &:hover {
            border: none;
        }

        h1 {
            margin: 0;
            font-size: 3.5rem;
        }
    }

    h2 {
        padding: 0 0 0 9.1rem;
        margin: -.5rem 0 0 0;
        font-size: 1.3rem;
        color: $gray-600;
    }
}

.header-area {
    display: none;
    background-color: $gray-200;

    @media (min-width: $breakpoints-lg) {
        display: block;
    }

    .container {
        background: $gray-200 url(/assets/img/header.jpg) right center no-repeat;
        background-size: contain;
        height: 15rem;
    }
}

.custom-logo,
mobile-logo {
    height: 3.5rem;
    padding-right: 2rem;
}

.header-menu {
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    position: sticky;
    top: 0;
    z-index: 40;
    background-color: #fff;
    display: none;

    @media (min-width: $breakpoints-lg) {
        display: block;
    }
}

.header-menu-inner {
    justify-content: space-between;
    align-items: center;
    display: flex;

}

.login-register-btn {
    @extend .transparent-small-btn;
    @extend .icon-transparent-btn;
    padding-right: 0;
}

.login-register {
    padding: 0;
    margin: 0;
    display: flex;
}

.header-mobile-menu {
    border-bottom: 1px solid $gray-300;
    padding: .5rem 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 40;
    background-color: $white;

    @media (max-width: $breakpoints-md - 1) {
        padding: 0;
    }

    @media (min-width: $breakpoints-lg) {
        display: none;
    }
}

.header-mobile-wrapper {
    @extend .row;
    align-items: center;
}

.header-mobile-meta {
    @extend .row;

    a {
        @extend .transparent-big-btn;
        @extend .single-icon-btn;
        margin-left: .7em;
    }

}

.header-mobile-logo a {
    display: flex;
    align-items: center;
    border: 0;
&:hover{
    color: $primary;
}
    h1 {
        margin: 0;

        @media (max-width: $breakpoints-md - 1) {
            font-size: 2rem;
        }
    }

    img {
        width: 5rem;
        padding-right: 1rem;

        @media (max-width: $breakpoints-md - 1) {
            width: 4rem;
        }
    }
}

.mobile-nav {
    position: fixed;
    top: 0;
    right: -39rem;
    width: 38rem;
    max-width: 80%;
    height: 100%;
    z-index: 999;
    background-color: $primary;
    overflow-y: auto;
    padding: 6rem 2rem 2rem 2rem;
    transition: all .3s linear;
    display: flex;
    flex-direction: column;

    &.open {
        right: 0;
    }

    .close-navbar-mobile {
        font-size: $font-size-h3;
        color: $white;
        position: absolute;
        top: 1.6rem;
        right: 3rem;
        z-index: 99;
    }

    .close-navbar-mobile svg {
        fill: $white;
        position: absolute;
        top: 0;
        right: 0;
        width: 2rem;
        height: 2rem;
    }
}

.toggle-bar:hover .icon-menu::before,
.toggle-bar:hover .icon-menu::after {

    background-color: $secondary;
    color: $secondary;
}