.toolbar, .canvas-container {
    max-width: 100%;
}
.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}
.toolbar a {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}
.toolbar a:hover {
    background-color: #e0e0e0;
}
.toolbar .icon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    display: inline-block;
    background-position: center;
}
.icon-prev {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/></svg>');
}
.icon-next {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"/></svg>');
}
.icon-download {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24px' height='24px' viewBox='0 0 24 24' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:rgb(0%25,0%25,0%25);fill-opacity:1;' d='M 13.5 1.5 C 13.5 0.671875 12.828125 0 12 0 C 11.171875 0 10.5 0.671875 10.5 1.5 L 10.5 12.875 L 7.058594 9.4375 C 6.472656 8.851562 5.523438 8.851562 4.9375 9.4375 C 4.351562 10.023438 4.351562 10.972656 4.9375 11.558594 L 10.9375 17.558594 C 11.523438 18.144531 12.472656 18.144531 13.058594 17.558594 L 19.058594 11.558594 C 19.644531 10.972656 19.644531 10.023438 19.058594 9.4375 C 18.472656 8.851562 17.523438 8.851562 16.9375 9.4375 L 13.5 12.875 Z M 3 16.5 C 1.34375 16.5 0 17.84375 0 19.5 L 0 21 C 0 22.65625 1.34375 24 3 24 L 21 24 C 22.65625 24 24 22.65625 24 21 L 24 19.5 C 24 17.84375 22.65625 16.5 21 16.5 L 16.242188 16.5 L 14.117188 18.625 C 12.945312 19.796875 11.046875 19.796875 9.875 18.625 L 7.757812 16.5 Z M 20.25 19.125 C 20.871094 19.125 21.375 19.628906 21.375 20.25 C 21.375 20.871094 20.871094 21.375 20.25 21.375 C 19.628906 21.375 19.125 20.871094 19.125 20.25 C 19.125 19.628906 19.628906 19.125 20.25 19.125 Z M 20.25 19.125 '/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 14px;
}
.page-info {
    font-size: 16px;
}
.canvas-container {
    text-align: center;
    width: 100%;
}
