.responsive-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;

    tr {
        background-color: #f8f8f8;
        border: 1px solid #ddd;
        padding: .35em;
    }

    th,
    td {
        padding: .625em;
        text-align: left;
    }

    th {
        letter-spacing: .1em;
        text-transform: uppercase;

        &.actions {
            min-width: 15rem;
        }
    }
}

@media screen and (max-width: $breakpoints-sm) {
    .responsive-table {
        border: 0;

        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right;
        }

        td::before {

            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }

        td:last-child {
            border-bottom: 0;
        }
    }

}

table.compact {
    margin: 2rem auto;
    width: auto;
    border: 1px solid $gray-700;
    border-collapse: collapse;

    td {
        padding: 0.4rem 0.8rem;
        border: 1px solid $gray-700;
        text-align: center;
    }
}

table.singleborder {
    margin: 2rem 0;
    padding: 2rem;
    width: 100%;
    border: 1px solid $gray-700;
    border-collapse: initial;
}

table.line {
    td {
        border-top: 1px solid $gray-400;
        padding: 1rem;
        vertical-align: top;
    }

    th {
        padding: 1rem;
    }
}