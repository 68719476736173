.lex-label-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1.5rem;
    
  }
.lex-label-cell {
    text-align: left;
    vertical-align: top;
    word-wrap: break-word;
    line-height: 2rem;
    position: relative;
    padding-left: 1.2rem; /* Espace suffisant pour inclure le carré et un peu de marge */
}

.lex-label-cell a {
    margin: 0; /* Eliminer la marge par défaut du paragraphe si nécessaire */
}

.lex-label-cell::before {
    content: '';
    position: absolute;
    left: 0;
    top: .8rem;
    transform: rotate(45deg); /* Ajouter la rotation ici */
    width: .5rem; /* Taille du carré */
    height: .5rem; /* Taille du carré */
    background-color: $secondary; /* Couleur du carré */
}
