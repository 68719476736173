.no-border {
    border: none !important;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mbt-1 {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mbt-2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

table.cel50 {
    width: 100%;

    td {
        width: 50%;
    }
}
table.cel33 {
    width: 100%;

    td {
        width: 33%;
    }
}
table.cel25 {
    width: 100%;

    td {
        width: 25%;
    }
}

.lg-w30 {
    @media (min-width: $breakpoints-lg) {
        width: 30% !important;
    }
}
.lg-w40 {
    @media (min-width: $breakpoints-lg) {
        width: 40% !important;
    }
}
.lg-w50 {
    @media (min-width: $breakpoints-lg) {
        width: 50% !important;
    }
}
.lg-w60 {
    @media (min-width: $breakpoints-lg) {
        width: 60% !important;
    }
}
.sm-w30 {
    @media (min-width: $breakpoints-sm) {
        width: 30% !important;
    }
}
.sm-w40 {
    @media (min-width: $breakpoints-sm) {
        width: 40% !important;
    }
}
.sm-w50 {
    @media (min-width: $breakpoints-sm) {
        width: 50% !important;
    }
}
.sm-w60 {
    @media (min-width: $breakpoints-sm) {
        width: 60% !important;
    }
}
.w100 {
    width: 100% !important;
}