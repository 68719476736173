/*
    Single selector
*/

%single-selector {

    input {
        display: none;
    }

    label {
        @extend .transparent-small-btn;
    }

    input:checked+label {
        color: #fff;
        background-color: $secondary;
    }
}


%field-box {
    border: 1px solid $gray-400;
    border-radius: 2px;

    select,
    input,
    textarea {

        position: relative;

        display: flex;
        flex: 1;

        width: 100%;
        padding: 0 1.5rem 1.5rem;

        cursor: pointer;

        color: $primary;
        border: 0;
        outline: 0;
        background-color: $gray-100;
        box-shadow: none;

        appearance: none;
    }

    select {

        ::after {
            position: absolute;
            top: 0;
            right: 0;

            padding: 0 1em;

            content: '\25BC';
            cursor: pointer;
            transition: .25s all ease;
            pointer-events: none;

            background: $primary;
        }

        :hover::after {
            color: $secondary;
        }

        /* Remove IE arrow */

        ::-ms-expand {
            display: none;
        }
    }

}

.std-input {
    width: 100%;
    height: 4.5rem;
    padding: 0 1.5rem;

    transition: all .3s linear;

    color: $primary;
    border: 1px solid $gray-300;
    border-radius: 3px;
    background-color: $gray-100;

    &.error {
        border-color: $red;
    }

    &:focus {
        background-color: $white;
    }
}

.field-error {
    font-size: $font-size-small;

    padding-left: 1.5rem;

    color: $red;
}

.field-label {
    &::after {
        @extend %chevron;
     }

    font-size: $font-size-smaller;
    font-weight: bold;

    padding: 1.5rem 1.5rem 1.2rem;

    text-transform: uppercase;

    color: $primary;

    cursor: pointer;

    width: 100%;
    font-family: 'Open Sans',
    sans-serif;
}

.color-checkbox {
    label {
        &::before {
            font-size: .7em;
            font-weight: bold;
            line-height: 1.2;

            display: inline-block;

            margin: 0 .6em .1em 0;
            padding: 0 .2em;

            content: '\2713';
            transition: .25s all ease;
            vertical-align: middle;

            color: white;
            border: 1px solid $primary;
            border-radius: 2px;
            background-color: $white;
        }
    }

    input:checked+label::before {
        border: 1px solid $secondary;
        background-color: $secondary;
    }

    input {
        display: none;
    }

}

.field-box-xl1 {
    @extend %col-xl1;
    @extend %field-box;
}

.field-box-sm1-xl2 {
    @extend %col-sm1-xl2;
    @extend %field-box;
}

.field-box-sm1-lg2-xl5 {
    @extend %col-sm1-lg2-xl5;
    @extend %field-box;
}

.field-box-sm1-lg3 {
    @extend %col-sm1-lg3;
    @extend %field-box;
}

.field-box-sm1-lg4 {
    @extend %col-sm1-lg4;
    @extend %field-box;
}