.login-register-page {
    padding-top: 2rem;
    padding-bottom: 12rem;

    input,
    .policy-checkbox,
    .checkbox-forgot {
        margin-top: 1rem;
    }

    button {
        margin-top: 2rem;
    }

    button {
        width: 100%;
    }

    .checkbox-forgot,
    .policy-checkbox {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-top: 1.5rem;
    }
}

.login,
.register {
    flex: 0 0 auto;

    width: 100%;
    margin-top: 4rem;

    @media(min-width: $breakpoints-xl) {
        width: 33.333333%;
    }

    @media(min-width: $breakpoints-lg) {
        width: 41.666667%;
    }
}

// @media (min-width: 1200px)
// .col-xl-4 {
// flex: 0 0 auto;
// width: 33.333333%;
// }
// @media (min-width: 992px)
// .col-lg-5 {
// flex: 0 0 auto;
// width: 41.666667%;
// }
