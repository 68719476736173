@font-face {
    font-family: "KaTeX_AMS";
    src: url("fonts/KaTeX_AMS-Regular.eot");
    src: url("fonts/KaTeX_AMS-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_AMS-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_AMS-Regular.woff") format("woff"),
        url("fonts/KaTeX_AMS-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Caligraphic";
    src: url("fonts/KaTeX_Caligraphic-Bold.eot");
    src: url("fonts/KaTeX_Caligraphic-Bold.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Caligraphic-Bold.woff2") format("woff2"),
        url("fonts/KaTeX_Caligraphic-Bold.woff") format("woff"),
        url("fonts/KaTeX_Caligraphic-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Caligraphic";
    src: url("fonts/KaTeX_Caligraphic-Regular.eot");
    src: url("fonts/KaTeX_Caligraphic-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Caligraphic-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_Caligraphic-Regular.woff") format("woff"),
        url("fonts/KaTeX_Caligraphic-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Fraktur";
    src: url("fonts/KaTeX_Fraktur-Bold.eot");
    src: url("fonts/KaTeX_Fraktur-Bold.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Fraktur-Bold.woff2") format("woff2"),
        url("fonts/KaTeX_Fraktur-Bold.woff") format("woff"),
        url("fonts/KaTeX_Fraktur-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Fraktur";
    src: url("fonts/KaTeX_Fraktur-Regular.eot");
    src: url("fonts/KaTeX_Fraktur-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Fraktur-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_Fraktur-Regular.woff") format("woff"),
        url("fonts/KaTeX_Fraktur-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Main";
    src: url("fonts/KaTeX_Main-Bold.eot");
    src: url("fonts/KaTeX_Main-Bold.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Main-Bold.woff2") format("woff2"),
        url("fonts/KaTeX_Main-Bold.woff") format("woff"),
        url("fonts/KaTeX_Main-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Main";
    src: url("fonts/KaTeX_Main-Italic.eot");
    src: url("fonts/KaTeX_Main-Italic.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Main-Italic.woff2") format("woff2"),
        url("fonts/KaTeX_Main-Italic.woff") format("woff"),
        url("fonts/KaTeX_Main-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "KaTeX_Main";
    src: url("fonts/KaTeX_Main-Regular.eot");
    src: url("fonts/KaTeX_Main-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Main-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_Main-Regular.woff") format("woff"),
        url("fonts/KaTeX_Main-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Math";
    src: url("fonts/KaTeX_Math-Italic.eot");
    src: url("fonts/KaTeX_Math-Italic.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Math-Italic.woff2") format("woff2"),
        url("fonts/KaTeX_Math-Italic.woff") format("woff"),
        url("fonts/KaTeX_Math-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "KaTeX_SansSerif";
    src: url("fonts/KaTeX_SansSerif-Regular.eot");
    src: url("fonts/KaTeX_SansSerif-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_SansSerif-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_SansSerif-Regular.woff") format("woff"),
        url("fonts/KaTeX_SansSerif-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Script";
    src: url("fonts/KaTeX_Script-Regular.eot");
    src: url("fonts/KaTeX_Script-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Script-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_Script-Regular.woff") format("woff"),
        url("fonts/KaTeX_Script-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Size1";
    src: url("fonts/KaTeX_Size1-Regular.eot");
    src: url("fonts/KaTeX_Size1-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Size1-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_Size1-Regular.woff") format("woff"),
        url("fonts/KaTeX_Size1-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Size2";
    src: url("fonts/KaTeX_Size2-Regular.eot");
    src: url("fonts/KaTeX_Size2-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Size2-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_Size2-Regular.woff") format("woff"),
        url("fonts/KaTeX_Size2-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Size3";
    src: url("fonts/KaTeX_Size3-Regular.eot");
    src: url("fonts/KaTeX_Size3-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Size3-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_Size3-Regular.woff") format("woff"),
        url("fonts/KaTeX_Size3-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Size4";
    src: url("fonts/KaTeX_Size4-Regular.eot");
    src: url("fonts/KaTeX_Size4-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Size4-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_Size4-Regular.woff") format("woff"),
        url("fonts/KaTeX_Size4-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KaTeX_Typewriter";
    src: url("fonts/KaTeX_Typewriter-Regular.eot");
    src: url("fonts/KaTeX_Typewriter-Regular.eot#iefix") format("embedded-opentype"),
        url("fonts/KaTeX_Typewriter-Regular.woff2") format("woff2"),
        url("fonts/KaTeX_Typewriter-Regular.woff") format("woff"),
        url("fonts/KaTeX_Typewriter-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.katex-display {
    display: block;
    margin: 1em 0;
    text-align: center;
}

.katex-display>.katex {
    display: inline-block;
    text-align: initial;
}

.katex {
    font: normal 1.21em KaTeX_Main, Times New Roman, serif;
    line-height: 1.2;
    white-space: nowrap;
    text-indent: 0;
    text-rendering: auto;
}

.katex .katex-html {
    display: inline-block;
}

.katex .katex-mathml {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.katex .base {
    display: inline-block;
}

.katex .strut {
    display: inline-block;
}

.katex .mathrm {
    font-style: normal;
}

.katex .textit {
    font-style: italic;
}

.katex .mathit {
    font-family: KaTeX_Math;
    font-style: italic;
}

.katex .mathbf {
    font-family: KaTeX_Main;
    font-weight: bold;
}

.katex .amsrm {
    font-family: KaTeX_AMS;
}

.katex .mathbb {
    font-family: KaTeX_AMS;
}

.katex .mathcal {
    font-family: KaTeX_Caligraphic;
}

.katex .mathfrak {
    font-family: KaTeX_Fraktur;
}

.katex .mathtt {
    font-family: KaTeX_Typewriter;
}

.katex .mathscr {
    font-family: KaTeX_Script;
}

.katex .mathsf {
    font-family: KaTeX_SansSerif;
}

.katex .mainit {
    font-family: KaTeX_Main;
    font-style: italic;
}

.katex .mord+.mop {
    margin-left: 0.16667em;
}

.katex .mord+.mbin {
    margin-left: 0.22222em;
}

.katex .mord+.mrel {
    margin-left: 0.27778em;
}

.katex .mord+.minner {
    margin-left: 0.16667em;
}

.katex .mop+.mord {
    margin-left: 0.16667em;
}

.katex .mop+.mop {
    margin-left: 0.16667em;
}

.katex .mop+.mrel {
    margin-left: 0.27778em;
}

.katex .mop+.minner {
    margin-left: 0.16667em;
}

.katex .mbin+.mord {
    margin-left: 0.22222em;
}

.katex .mbin+.mop {
    margin-left: 0.22222em;
}

.katex .mbin+.mopen {
    margin-left: 0.22222em;
}

.katex .mbin+.minner {
    margin-left: 0.22222em;
}

.katex .mrel+.mord {
    margin-left: 0.27778em;
}

.katex .mrel+.mop {
    margin-left: 0.27778em;
}

.katex .mrel+.mopen {
    margin-left: 0.27778em;
}

.katex .mrel+.minner {
    margin-left: 0.27778em;
}

.katex .mclose+.mop {
    margin-left: 0.16667em;
}

.katex .mclose+.mbin {
    margin-left: 0.22222em;
}

.katex .mclose+.mrel {
    margin-left: 0.27778em;
}

.katex .mclose+.minner {
    margin-left: 0.16667em;
}

.katex .mpunct+.mord {
    margin-left: 0.16667em;
}

.katex .mpunct+.mop {
    margin-left: 0.16667em;
}

.katex .mpunct+.mrel {
    margin-left: 0.16667em;
}

.katex .mpunct+.mopen {
    margin-left: 0.16667em;
}

.katex .mpunct+.mclose {
    margin-left: 0.16667em;
}

.katex .mpunct+.mpunct {
    margin-left: 0.16667em;
}

.katex .mpunct+.minner {
    margin-left: 0.16667em;
}

.katex .minner+.mord {
    margin-left: 0.16667em;
}

.katex .minner+.mop {
    margin-left: 0.16667em;
}

.katex .minner+.mbin {
    margin-left: 0.22222em;
}

.katex .minner+.mrel {
    margin-left: 0.27778em;
}

.katex .minner+.mopen {
    margin-left: 0.16667em;
}

.katex .minner+.mpunct {
    margin-left: 0.16667em;
}

.katex .minner+.minner {
    margin-left: 0.16667em;
}

.katex .mord.mtight {
    margin-left: 0;
}

.katex .mop.mtight {
    margin-left: 0;
}

.katex .mbin.mtight {
    margin-left: 0;
}

.katex .mrel.mtight {
    margin-left: 0;
}

.katex .mopen.mtight {
    margin-left: 0;
}

.katex .mclose.mtight {
    margin-left: 0;
}

.katex .mpunct.mtight {
    margin-left: 0;
}

.katex .minner.mtight {
    margin-left: 0;
}

.katex .mord+.mop.mtight {
    margin-left: 0.16667em;
}

.katex .mop+.mord.mtight {
    margin-left: 0.16667em;
}

.katex .mop+.mop.mtight {
    margin-left: 0.16667em;
}

.katex .mclose+.mop.mtight {
    margin-left: 0.16667em;
}

.katex .minner+.mop.mtight {
    margin-left: 0.16667em;
}

.katex .reset-textstyle.textstyle {
    font-size: 1em;
}

.katex .reset-textstyle.scriptstyle {
    font-size: 0.7em;
}

.katex .reset-textstyle.scriptscriptstyle {
    font-size: 0.5em;
}

.katex .reset-scriptstyle.textstyle {
    font-size: 1.42857em;
}

.katex .reset-scriptstyle.scriptstyle {
    font-size: 1em;
}

.katex .reset-scriptstyle.scriptscriptstyle {
    font-size: 0.71429em;
}

.katex .reset-scriptscriptstyle.textstyle {
    font-size: 2em;
}

.katex .reset-scriptscriptstyle.scriptstyle {
    font-size: 1.4em;
}

.katex .reset-scriptscriptstyle.scriptscriptstyle {
    font-size: 1em;
}

.katex .style-wrap {
    position: relative;
}

.katex .vlist {
    display: inline-block;
}

.katex .vlist>span {
    display: block;
    height: 0;
    position: relative;
}

.katex .vlist>span>span {
    display: inline-block;
}

.katex .vlist .baseline-fix {
    display: inline-table;
    table-layout: fixed;
}

.katex .msupsub {
    text-align: left;
}

.katex .mfrac>span>span {
    text-align: center;
}

.katex .mfrac .frac-line {
    width: 100%;
}

.katex .mfrac .frac-line:before {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    content: "";
    display: block;
}

.katex .mfrac .frac-line:after {
    border-bottom-style: solid;
    border-bottom-width: 0.04em;
    content: "";
    display: block;
    margin-top: -1px;
}

.katex .mspace {
    display: inline-block;
}

.katex .mspace.negativethinspace {
    margin-left: -0.16667em;
}

.katex .mspace.thinspace {
    width: 0.16667em;
}

.katex .mspace.negativemediumspace {
    margin-left: -0.22222em;
}

.katex .mspace.mediumspace {
    width: 0.22222em;
}

.katex .mspace.thickspace {
    width: 0.27778em;
}

.katex .mspace.sixmuspace {
    width: 0.333333em;
}

.katex .mspace.eightmuspace {
    width: 0.444444em;
}

.katex .mspace.enspace {
    width: 0.5em;
}

.katex .mspace.twelvemuspace {
    width: 0.666667em;
}

.katex .mspace.quad {
    width: 1em;
}

.katex .mspace.qquad {
    width: 2em;
}

.katex .llap,
.katex .rlap {
    width: 0;
    position: relative;
}

.katex .llap>.inner,
.katex .rlap>.inner {
    position: absolute;
}

.katex .llap>.fix,
.katex .rlap>.fix {
    display: inline-block;
}

.katex .llap>.inner {
    right: 0;
}

.katex .rlap>.inner {
    left: 0;
}

.katex .katex-logo .a {
    font-size: 0.75em;
    margin-left: -0.32em;
    position: relative;
    top: -0.2em;
}

.katex .katex-logo .t {
    margin-left: -0.23em;
}

.katex .katex-logo .e {
    margin-left: -0.1667em;
    position: relative;
    top: 0.2155em;
}

.katex .katex-logo .x {
    margin-left: -0.125em;
}

.katex .rule {
    display: inline-block;
    border: solid 0;
    position: relative;
}

.katex .overline .overline-line,
.katex .underline .underline-line {
    width: 100%;
}

.katex .overline .overline-line:before,
.katex .underline .underline-line:before {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    content: "";
    display: block;
}

.katex .overline .overline-line:after,
.katex .underline .underline-line:after {
    border-bottom-style: solid;
    border-bottom-width: 0.04em;
    content: "";
    display: block;
    margin-top: -1px;
}

.katex .sqrt>.sqrt-sign {
    position: relative;
}

.katex .sqrt .sqrt-line {
    width: 100%;
}

.katex .sqrt .sqrt-line:before {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    content: "";
    display: block;
}

.katex .sqrt .sqrt-line:after {
    border-bottom-style: solid;
    border-bottom-width: 0.04em;
    content: "";
    display: block;
    margin-top: -1px;
}

.katex .sqrt>.root {
    margin-left: 0.27777778em;
    margin-right: -0.55555556em;
}

.katex .sizing,
.katex .fontsize-ensurer {
    display: inline-block;
}

.katex .sizing.reset-size1.size1,
.katex .fontsize-ensurer.reset-size1.size1 {
    font-size: 1em;
}

.katex .sizing.reset-size1.size2,
.katex .fontsize-ensurer.reset-size1.size2 {
    font-size: 1.4em;
}

.katex .sizing.reset-size1.size3,
.katex .fontsize-ensurer.reset-size1.size3 {
    font-size: 1.6em;
}

.katex .sizing.reset-size1.size4,
.katex .fontsize-ensurer.reset-size1.size4 {
    font-size: 1.8em;
}

.katex .sizing.reset-size1.size5,
.katex .fontsize-ensurer.reset-size1.size5 {
    font-size: 2em;
}

.katex .sizing.reset-size1.size6,
.katex .fontsize-ensurer.reset-size1.size6 {
    font-size: 2.4em;
}

.katex .sizing.reset-size1.size7,
.katex .fontsize-ensurer.reset-size1.size7 {
    font-size: 2.88em;
}

.katex .sizing.reset-size1.size8,
.katex .fontsize-ensurer.reset-size1.size8 {
    font-size: 3.46em;
}

.katex .sizing.reset-size1.size9,
.katex .fontsize-ensurer.reset-size1.size9 {
    font-size: 4.14em;
}

.katex .sizing.reset-size1.size10,
.katex .fontsize-ensurer.reset-size1.size10 {
    font-size: 4.98em;
}

.katex .sizing.reset-size2.size1,
.katex .fontsize-ensurer.reset-size2.size1 {
    font-size: 0.71428571em;
}

.katex .sizing.reset-size2.size2,
.katex .fontsize-ensurer.reset-size2.size2 {
    font-size: 1em;
}

.katex .sizing.reset-size2.size3,
.katex .fontsize-ensurer.reset-size2.size3 {
    font-size: 1.14285714em;
}

.katex .sizing.reset-size2.size4,
.katex .fontsize-ensurer.reset-size2.size4 {
    font-size: 1.28571429em;
}

.katex .sizing.reset-size2.size5,
.katex .fontsize-ensurer.reset-size2.size5 {
    font-size: 1.42857143em;
}

.katex .sizing.reset-size2.size6,
.katex .fontsize-ensurer.reset-size2.size6 {
    font-size: 1.71428571em;
}

.katex .sizing.reset-size2.size7,
.katex .fontsize-ensurer.reset-size2.size7 {
    font-size: 2.05714286em;
}

.katex .sizing.reset-size2.size8,
.katex .fontsize-ensurer.reset-size2.size8 {
    font-size: 2.47142857em;
}

.katex .sizing.reset-size2.size9,
.katex .fontsize-ensurer.reset-size2.size9 {
    font-size: 2.95714286em;
}

.katex .sizing.reset-size2.size10,
.katex .fontsize-ensurer.reset-size2.size10 {
    font-size: 3.55714286em;
}

.katex .sizing.reset-size3.size1,
.katex .fontsize-ensurer.reset-size3.size1 {
    font-size: 0.625em;
}

.katex .sizing.reset-size3.size2,
.katex .fontsize-ensurer.reset-size3.size2 {
    font-size: 0.875em;
}

.katex .sizing.reset-size3.size3,
.katex .fontsize-ensurer.reset-size3.size3 {
    font-size: 1em;
}

.katex .sizing.reset-size3.size4,
.katex .fontsize-ensurer.reset-size3.size4 {
    font-size: 1.125em;
}

.katex .sizing.reset-size3.size5,
.katex .fontsize-ensurer.reset-size3.size5 {
    font-size: 1.25em;
}

.katex .sizing.reset-size3.size6,
.katex .fontsize-ensurer.reset-size3.size6 {
    font-size: 1.5em;
}

.katex .sizing.reset-size3.size7,
.katex .fontsize-ensurer.reset-size3.size7 {
    font-size: 1.8em;
}

.katex .sizing.reset-size3.size8,
.katex .fontsize-ensurer.reset-size3.size8 {
    font-size: 2.1625em;
}

.katex .sizing.reset-size3.size9,
.katex .fontsize-ensurer.reset-size3.size9 {
    font-size: 2.5875em;
}

.katex .sizing.reset-size3.size10,
.katex .fontsize-ensurer.reset-size3.size10 {
    font-size: 3.1125em;
}

.katex .sizing.reset-size4.size1,
.katex .fontsize-ensurer.reset-size4.size1 {
    font-size: 0.55555556em;
}

.katex .sizing.reset-size4.size2,
.katex .fontsize-ensurer.reset-size4.size2 {
    font-size: 0.77777778em;
}

.katex .sizing.reset-size4.size3,
.katex .fontsize-ensurer.reset-size4.size3 {
    font-size: 0.88888889em;
}

.katex .sizing.reset-size4.size4,
.katex .fontsize-ensurer.reset-size4.size4 {
    font-size: 1em;
}

.katex .sizing.reset-size4.size5,
.katex .fontsize-ensurer.reset-size4.size5 {
    font-size: 1.11111111em;
}

.katex .sizing.reset-size4.size6,
.katex .fontsize-ensurer.reset-size4.size6 {
    font-size: 1.33333333em;
}

.katex .sizing.reset-size4.size7,
.katex .fontsize-ensurer.reset-size4.size7 {
    font-size: 1.6em;
}

.katex .sizing.reset-size4.size8,
.katex .fontsize-ensurer.reset-size4.size8 {
    font-size: 1.92222222em;
}

.katex .sizing.reset-size4.size9,
.katex .fontsize-ensurer.reset-size4.size9 {
    font-size: 2.3em;
}

.katex .sizing.reset-size4.size10,
.katex .fontsize-ensurer.reset-size4.size10 {
    font-size: 2.76666667em;
}

.katex .sizing.reset-size5.size1,
.katex .fontsize-ensurer.reset-size5.size1 {
    font-size: 0.5em;
}

.katex .sizing.reset-size5.size2,
.katex .fontsize-ensurer.reset-size5.size2 {
    font-size: 0.7em;
}

.katex .sizing.reset-size5.size3,
.katex .fontsize-ensurer.reset-size5.size3 {
    font-size: 0.8em;
}

.katex .sizing.reset-size5.size4,
.katex .fontsize-ensurer.reset-size5.size4 {
    font-size: 0.9em;
}

.katex .sizing.reset-size5.size5,
.katex .fontsize-ensurer.reset-size5.size5 {
    font-size: 1em;
}

.katex .sizing.reset-size5.size6,
.katex .fontsize-ensurer.reset-size5.size6 {
    font-size: 1.2em;
}

.katex .sizing.reset-size5.size7,
.katex .fontsize-ensurer.reset-size5.size7 {
    font-size: 1.44em;
}

.katex .sizing.reset-size5.size8,
.katex .fontsize-ensurer.reset-size5.size8 {
    font-size: 1.73em;
}

.katex .sizing.reset-size5.size9,
.katex .fontsize-ensurer.reset-size5.size9 {
    font-size: 2.07em;
}

.katex .sizing.reset-size5.size10,
.katex .fontsize-ensurer.reset-size5.size10 {
    font-size: 2.49em;
}

.katex .sizing.reset-size6.size1,
.katex .fontsize-ensurer.reset-size6.size1 {
    font-size: 0.41666667em;
}

.katex .sizing.reset-size6.size2,
.katex .fontsize-ensurer.reset-size6.size2 {
    font-size: 0.58333333em;
}

.katex .sizing.reset-size6.size3,
.katex .fontsize-ensurer.reset-size6.size3 {
    font-size: 0.66666667em;
}

.katex .sizing.reset-size6.size4,
.katex .fontsize-ensurer.reset-size6.size4 {
    font-size: 0.75em;
}

.katex .sizing.reset-size6.size5,
.katex .fontsize-ensurer.reset-size6.size5 {
    font-size: 0.83333333em;
}

.katex .sizing.reset-size6.size6,
.katex .fontsize-ensurer.reset-size6.size6 {
    font-size: 1em;
}

.katex .sizing.reset-size6.size7,
.katex .fontsize-ensurer.reset-size6.size7 {
    font-size: 1.2em;
}

.katex .sizing.reset-size6.size8,
.katex .fontsize-ensurer.reset-size6.size8 {
    font-size: 1.44166667em;
}

.katex .sizing.reset-size6.size9,
.katex .fontsize-ensurer.reset-size6.size9 {
    font-size: 1.725em;
}

.katex .sizing.reset-size6.size10,
.katex .fontsize-ensurer.reset-size6.size10 {
    font-size: 2.075em;
}

.katex .sizing.reset-size7.size1,
.katex .fontsize-ensurer.reset-size7.size1 {
    font-size: 0.34722222em;
}

.katex .sizing.reset-size7.size2,
.katex .fontsize-ensurer.reset-size7.size2 {
    font-size: 0.48611111em;
}

.katex .sizing.reset-size7.size3,
.katex .fontsize-ensurer.reset-size7.size3 {
    font-size: 0.55555556em;
}

.katex .sizing.reset-size7.size4,
.katex .fontsize-ensurer.reset-size7.size4 {
    font-size: 0.625em;
}

.katex .sizing.reset-size7.size5,
.katex .fontsize-ensurer.reset-size7.size5 {
    font-size: 0.69444444em;
}

.katex .sizing.reset-size7.size6,
.katex .fontsize-ensurer.reset-size7.size6 {
    font-size: 0.83333333em;
}

.katex .sizing.reset-size7.size7,
.katex .fontsize-ensurer.reset-size7.size7 {
    font-size: 1em;
}

.katex .sizing.reset-size7.size8,
.katex .fontsize-ensurer.reset-size7.size8 {
    font-size: 1.20138889em;
}

.katex .sizing.reset-size7.size9,
.katex .fontsize-ensurer.reset-size7.size9 {
    font-size: 1.4375em;
}

.katex .sizing.reset-size7.size10,
.katex .fontsize-ensurer.reset-size7.size10 {
    font-size: 1.72916667em;
}

.katex .sizing.reset-size8.size1,
.katex .fontsize-ensurer.reset-size8.size1 {
    font-size: 0.28901734em;
}

.katex .sizing.reset-size8.size2,
.katex .fontsize-ensurer.reset-size8.size2 {
    font-size: 0.40462428em;
}

.katex .sizing.reset-size8.size3,
.katex .fontsize-ensurer.reset-size8.size3 {
    font-size: 0.46242775em;
}

.katex .sizing.reset-size8.size4,
.katex .fontsize-ensurer.reset-size8.size4 {
    font-size: 0.52023121em;
}

.katex .sizing.reset-size8.size5,
.katex .fontsize-ensurer.reset-size8.size5 {
    font-size: 0.57803468em;
}

.katex .sizing.reset-size8.size6,
.katex .fontsize-ensurer.reset-size8.size6 {
    font-size: 0.69364162em;
}

.katex .sizing.reset-size8.size7,
.katex .fontsize-ensurer.reset-size8.size7 {
    font-size: 0.83236994em;
}

.katex .sizing.reset-size8.size8,
.katex .fontsize-ensurer.reset-size8.size8 {
    font-size: 1em;
}

.katex .sizing.reset-size8.size9,
.katex .fontsize-ensurer.reset-size8.size9 {
    font-size: 1.19653179em;
}

.katex .sizing.reset-size8.size10,
.katex .fontsize-ensurer.reset-size8.size10 {
    font-size: 1.43930636em;
}

.katex .sizing.reset-size9.size1,
.katex .fontsize-ensurer.reset-size9.size1 {
    font-size: 0.24154589em;
}

.katex .sizing.reset-size9.size2,
.katex .fontsize-ensurer.reset-size9.size2 {
    font-size: 0.33816425em;
}

.katex .sizing.reset-size9.size3,
.katex .fontsize-ensurer.reset-size9.size3 {
    font-size: 0.38647343em;
}

.katex .sizing.reset-size9.size4,
.katex .fontsize-ensurer.reset-size9.size4 {
    font-size: 0.43478261em;
}

.katex .sizing.reset-size9.size5,
.katex .fontsize-ensurer.reset-size9.size5 {
    font-size: 0.48309179em;
}

.katex .sizing.reset-size9.size6,
.katex .fontsize-ensurer.reset-size9.size6 {
    font-size: 0.57971014em;
}

.katex .sizing.reset-size9.size7,
.katex .fontsize-ensurer.reset-size9.size7 {
    font-size: 0.69565217em;
}

.katex .sizing.reset-size9.size8,
.katex .fontsize-ensurer.reset-size9.size8 {
    font-size: 0.83574879em;
}

.katex .sizing.reset-size9.size9,
.katex .fontsize-ensurer.reset-size9.size9 {
    font-size: 1em;
}

.katex .sizing.reset-size9.size10,
.katex .fontsize-ensurer.reset-size9.size10 {
    font-size: 1.20289855em;
}

.katex .sizing.reset-size10.size1,
.katex .fontsize-ensurer.reset-size10.size1 {
    font-size: 0.20080321em;
}

.katex .sizing.reset-size10.size2,
.katex .fontsize-ensurer.reset-size10.size2 {
    font-size: 0.2811245em;
}

.katex .sizing.reset-size10.size3,
.katex .fontsize-ensurer.reset-size10.size3 {
    font-size: 0.32128514em;
}

.katex .sizing.reset-size10.size4,
.katex .fontsize-ensurer.reset-size10.size4 {
    font-size: 0.36144578em;
}

.katex .sizing.reset-size10.size5,
.katex .fontsize-ensurer.reset-size10.size5 {
    font-size: 0.40160643em;
}

.katex .sizing.reset-size10.size6,
.katex .fontsize-ensurer.reset-size10.size6 {
    font-size: 0.48192771em;
}

.katex .sizing.reset-size10.size7,
.katex .fontsize-ensurer.reset-size10.size7 {
    font-size: 0.57831325em;
}

.katex .sizing.reset-size10.size8,
.katex .fontsize-ensurer.reset-size10.size8 {
    font-size: 0.69477912em;
}

.katex .sizing.reset-size10.size9,
.katex .fontsize-ensurer.reset-size10.size9 {
    font-size: 0.8313253em;
}

.katex .sizing.reset-size10.size10,
.katex .fontsize-ensurer.reset-size10.size10 {
    font-size: 1em;
}

.katex .delimsizing.size1 {
    font-family: KaTeX_Size1;
}

.katex .delimsizing.size2 {
    font-family: KaTeX_Size2;
}

.katex .delimsizing.size3 {
    font-family: KaTeX_Size3;
}

.katex .delimsizing.size4 {
    font-family: KaTeX_Size4;
}

.katex .delimsizing.mult .delim-size1>span {
    font-family: KaTeX_Size1;
}

.katex .delimsizing.mult .delim-size4>span {
    font-family: KaTeX_Size4;
}

.katex .nulldelimiter {
    display: inline-block;
    width: 0.12em;
}

.katex .op-symbol {
    position: relative;
}

.katex .op-symbol.small-op {
    font-family: KaTeX_Size1;
}

.katex .op-symbol.large-op {
    font-family: KaTeX_Size2;
}

.katex .op-limits>.vlist>span {
    text-align: center;
}

.katex .accent>.vlist>span {
    text-align: center;
}

.katex .accent .accent-body>span {
    width: 0;
}

.katex .accent .accent-body.accent-vec>span {
    position: relative;
    left: 0.326em;
}

.katex .mtable .vertical-separator {
    display: inline-block;
    margin: 0 -0.025em;
    border-right: 0.05em solid black;
}

.katex .mtable .arraycolsep {
    display: inline-block;
}

.katex .mtable .col-align-c>.vlist {
    text-align: center;
}

.katex .mtable .col-align-l>.vlist {
    text-align: left;
}

.katex .mtable .col-align-r>.vlist {
    text-align: right;
}

.katex .stretchy {
    width: 100%;
    display: block;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 100% 100%;
}

.katex .stretchy:before,
.katex .stretchy:after {
    content: "";
    display: block;
}

.katex .x-arrow>span>span {
    text-align: center;
}

.katex .x-arrow>span>span>span>.mord {
    padding: 0 0.5em 0 0.5em;
}

.katex .mover>span>span,
.katex .munder>span>span {
    text-align: center;
}

.katex .boxed {
    display: inline-block;
    padding: 0 0.278em 0 0.25em;
    border: 0.04em solid black;
}

.katex .outer-wrap {
    display: inline-block;
    position: relative;
}

.katex .outer-wrap>.inner-wrap {
    display: inline-block;
    padding: 0;
}

.katex .outer-wrap>.strike {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
}

.katex .outer-wrap>.strike:before,
.katex .outer-wrap>.strike:after {
    content: "";
}

.katex .sout {
    border-bottom-style: solid;
    border-bottom-width: 0.08em;
}

.katex .overbracket,
.katex .underbracket {
    box-sizing: border-box;
    height: 0.3em;
    border-style: solid;
}

.katex .overbracket {
    border-width: 0.12em 0.12em 0 0.12em;
}

.katex .underbracket {
    border-width: 0 0.12em 0.12em 0.12em;
}

.katex .widehat1 {
    height: 0.16em;
    background-image: url(images/widehat1.svg);
}

.katex .widehat2 {
    height: 0.23em;
    background-image: url(images/widehat2.svg);
}

.katex .widehat3 {
    height: 0.28em;
    background-image: url(images/widehat3.svg);
}

.katex .widehat4 {
    height: 0.34em;
    background-image: url(images/widehat4.svg);
}

// .katex .tilde1 {
//     height: 0.14em;
//     background-image: url(images/tilde1.svg);
// }

// .katex .tilde2 {
//     height: 0.195em;
//     background-image: url(images/tilde2.svg);
// }

// .katex .tilde3 {
//     height: 0.26em;
//     background-image: url(images/tilde3.svg);
// }

// .katex .tilde4 {
//     height: 0.334em;
//     background-image: url(images/tilde4.svg);
// }

.katex .rightarrow {
    height: 0.334em;
    min-width: 0.5em;
    background-image: url(images/rightarrow.svg);
}

.katex .xrightarrow {
    height: 0.522em;
    min-width: 0.783em;
    background-image: url(images/xrightarrow.svg);
}

// .katex .leftarrow {
//     height: 0.334em;
//     min-width: 0.5em;
//     background-image: url(images/leftarrow.svg);
// }

// .katex .xleftarrow {
//     height: 0.522em;
//     min-width: 0.783em;
//     background-image: url(images/xleftarrow.svg);
// }

// .katex .overbrace {
//     height: 0.548em;
//     min-width: 1.6em;
//     background-image: url(images/overbrace.svg);
// }

// .katex .underbrace {
//     height: 0.548em;
//     min-width: 1.6em;
//     background-image: url(images/underbrace.svg);
// }

// .katex .leftrightarrow {
//     height: 0.334em;
//     min-width: 0.5em;
//     background-image: url(images/leftrightarrow.svg);
// }

// .katex .xleftrightarrow {
//     height: 0.522em;
//     min-width: 0.783em;
//     background-image: url(images/xleftrightarrow.svg);
// }

// .katex .doublerightarrow {
//     height: 0.56em;
//     min-width: 0.783em;
//     background-image: url(images/doublerightarrow.svg);
// }

// .katex .doubleleftarrow {
//     height: 0.56em;
//     min-width: 0.783em;
//     background-image: url(images/doubleleftarrow.svg);
// }

// .katex .doubleleftrightarrow {
//     height: 0.56em;
//     min-width: 0.955em;
//     background-image: url(images/doubleleftrightarrow.svg);
// }

// .katex .leftharpoon {
//     height: 0.334em;
//     min-width: 0.5em;
//     background-image: url(images/leftharpoon.svg);
// }

// .katex .xleftharpoon {
//     height: 0.522em;
//     min-width: 0.783em;
//     background-image: url(images/xleftharpoon.svg);
// }

// .katex .rightharpoon {
//     height: 0.334em;
//     min-width: 0.5em;
//     background-image: url(images/rightharpoon.svg);
// }

// .katex .xrightharpoon {
//     height: 0.522em;
//     min-width: 0.783em;
//     background-image: url(images/xrightharpoon.svg);
// }

// .katex .hookleftarrow {
//     height: 0.522em;
//     min-width: 0.87em;
//     background-image: url(images/hookleftarrow.svg);
// }

// .katex .hookrightarrow {
//     min-width: 0.87em;
//     height: 0.522em;
//     background-image: url(images/hookrightarrow.svg);
// }

// .katex .mapsto {
//     height: 0.522em;
//     min-width: 0.783em;
//     background-image: url(images/mapsto.svg);
// }

// .katex .leftharpoondown {
//     height: 0.334em;
//     min-width: 0.5em;
//     background-image: url(images/leftharpoondown.svg);
// }

// .katex .xleftharpoondown {
//     height: 0.522em;
//     min-width: 0.783em;
//     background-image: url(images/xleftharpoondown.svg);
// }

// .katex .rightharpoondown {
//     height: 0.334em;
//     min-width: 0.5em;
//     background-image: url(images/rightharpoondown.svg);
// }

// .katex .xrightharpoondown {
//     height: 0.522em;
//     min-width: 0.783em;
//     background-image: url(images/xrightharpoondown.svg);
// }

// .katex .rightleftharpoons {
//     height: 0.716em;
//     min-width: 0.783em;
//     background-image: url(images/rightleftharpoons.svg);
// }

// .katex .leftrightharpoons {
//     height: 0.716em;
//     min-width: 0.783em;
//     background-image: url(images/leftrightharpoons.svg);
// }

// .katex .overgroup {
//     height: 0.262em;
//     min-width: 0.87em;
//     background-image: url(images/overgroup.svg);
// }

// .katex .undergroup {
//     height: 0.262em;
//     min-width: 0.87em;
//     background-image: url(images/undergroup.svg);
// }

// .katex .twoheadleftarrow {
//     height: 0.334em;
//     min-width: 0.86em;
//     background-image: url(images/twoheadleftarrow.svg);
// }

// .katex .twoheadrightarrow {
//     height: 0.334em;
//     min-width: 0.86em;
//     background-image: url(images/twoheadrightarrow.svg);
// }

// .katex .linesegment {
//     height: 0.334em;
//     min-width: 0.5em;
//     background-image: url(images/linesegment.svg);
// }

// .katex .longequal {
//     height: 0.334em;
//     min-width: 0.5em;
//     background-image: url(images/longequal.svg);
// }

// .katex .tofrom {
//     height: 0.528em;
//     min-width: 0.86em;
//     background-image: url(images/tofrom.svg);
// }

// .katex .rightleftarrows {
//     height: 0.902em;
//     min-width: 2em;
//     background-image: url(images/rightleftarrows.svg);
// }

.katex .outer-wrap>.cancel {
    background: linear-gradient(to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%);
}

.katex .outer-wrap>.bcancel {
    background: linear-gradient(to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%);
}

.katex .outer-wrap>.xcancel {
    background: linear-gradient(to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%),
        linear-gradient(to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%);
}

@media screen and (min-width: 0\0) {

    .katex .stretchy,
    .katex .strike {
        background-color: transparent !important;
    }

//     .katex .outer-wrap>.bcancel,
//     .katex .outer-wrap>.bcancel-mask {
//         background-image: url(images/bcancel.svg);
//     }

//     .katex .outer-wrap>.cancel,
//     .katex .outer-wrap>.cancel-mask {
//         background-image: url(images/cancel.svg);
//     }

//     .katex .outer-wrap>.xcancel,
//     .katex .outer-wrap>.xcancel-mask {
//         background-image: url(images/xcancel.svg);
//     }
 }

@supports ((mask-image: none) or (-webkit-mask: none)) {
    .katex .mask {
        background-image: none;
    }
}

@supports not ((mask-image: none) or (-webkit-mask: none)) {

    .katex .stretchy,
    .katex .strike {
        background-color: transparent !important;
    }

    .katex .outer-wrap>.cancel-mask {
        background: linear-gradient(to top left,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 0.04em),
                #000000 calc(50% - 0.04em),
                #000000 calc(50% + 0.04em),
                rgba(0, 0, 0, 0) calc(50% + 0.04em),
                rgba(0, 0, 0, 0) 100%);
    }

    .katex .outer-wrap>.bcancel-mask {
        background: linear-gradient(to top right,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 0.04em),
                #000000 calc(50% - 0.04em),
                #000000 calc(50% + 0.04em),
                rgba(0, 0, 0, 0) calc(50% + 0.04em),
                rgba(0, 0, 0, 0) 100%);
    }

    .katex .outer-wrap>.xcancel-mask {
        background: linear-gradient(to top left,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 0.04em),
                #000000 calc(50% - 0.04em),
                #000000 calc(50% + 0.04em),
                rgba(0, 0, 0, 0) calc(50% + 0.04em),
                rgba(0, 0, 0, 0) 100%),
            linear-gradient(to top right,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) calc(50% - 0.04em),
                #000000 calc(50% - 0.04em),
                #000000 calc(50% + 0.04em),
                rgba(0, 0, 0, 0) calc(50% + 0.04em),
                rgba(0, 0, 0, 0) 100%);
    }
}

.katex .outer-wrap>.cancel-mask {
    mask-image: linear-gradient(to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: linear-gradient(to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%);
}

.katex .outer-wrap>.bcancel-mask {
    mask-image: linear-gradient(to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: linear-gradient(to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%);
}

.katex .outer-wrap>.xcancel-mask {
    mask-image: linear-gradient(to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%),
        linear-gradient(to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: linear-gradient(to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%),
        linear-gradient(to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.04em),
            #000000 calc(50% - 0.04em),
            #000000 calc(50% + 0.04em),
            rgba(0, 0, 0, 0) calc(50% + 0.04em),
            rgba(0, 0, 0, 0) 100%);
}

@supports (-ms-touch-action: none) {
    .katex .mask {
        background-color: transparent !important;
    }

//     .katex .outer-wrap>.bcancel,
//     .katex .outer-wrap>.bcancel-mask {
//         background-image: url(images/bcancel.svg);
//     }

//     .katex .outer-wrap>.cancel,
//     .katex .outer-wrap>.cancel-mask {
//         background-image: url(images/cancel.svg);
//     }

//     .katex .outer-wrap>.xcancel,
//     .katex .outer-wrap>.xcancel-mask {
//         background-image: url(images/xcancel.svg);
//     }
 }

.katex .widehat1-mask {
    -webkit-mask: url(images/widehat1.svg);
    mask: url(images/widehat1.svg) no-repeat;
}

.katex .widehat2-mask {
    -webkit-mask: url(images/widehat2.svg);
    mask: url(images/widehat2.svg) no-repeat;
}

.katex .widehat3-mask {
    -webkit-mask: url(images/widehat3.svg);
    mask: url(images/widehat3.svg) no-repeat;
}

.katex .widehat4-mask {
    -webkit-mask: url(images/widehat4.svg);
    mask: url(images/widehat4.svg) no-repeat;
}

// .katex .tilde1-mask {
//     -webkit-mask: url(images/tilde1.svg);
//     mask: url(images/tilde1.svg) no-repeat;
// }

// .katex .tilde2-mask {
//     -webkit-mask: url(images/tilde2.svg);
//     mask: url(images/tilde2.svg) no-repeat;
// }

// .katex .tilde3-mask {
//     -webkit-mask: url(images/tilde3.svg);
//     mask: url(images/tilde3.svg) no-repeat;
// }

// .katex .tilde4-mask {
//     -webkit-mask: url(images/tilde4.svg);
//     mask: url(images/tilde4.svg) no-repeat;
// }

.katex .rightarrow-mask {
    mask: url(images/rightarrow.svg);
    -webkit-mask: url(images/rightarrow.svg);
}

.katex .xrightarrow-mask {
    mask: url(images/xrightarrow.svg);
    -webkit-mask: url(images/xrightarrow.svg);
}

// .katex .leftarrow-mask {
//     mask: url(images/leftarrow.svg);
//     -webkit-mask: url(images/leftarrow.svg);
// }

// .katex .xleftarrow-mask {
//     mask: url(images/xleftarrow.svg);
//     -webkit-mask: url(images/xleftarrow.svg);
// }

// .katex .overbrace-mask {
//     min-width: 1.6em;
//     mask: url(images/overbrace.svg);
//     -webkit-mask: url(images/overbrace.svg);
// }

// .katex .underbrace-mask {
//     min-width: 1.6em;
//     mask: url(images/underbrace.svg);
//     -webkit-mask: url(images/underbrace.svg);
// }

// .katex .leftrightarrow-mask {
//     mask: url(images/leftrightarrow.svg);
//     -webkit-mask: url(images/leftrightarrow.svg);
// }

// .katex .xleftrightarrow-mask {
//     mask: url(images/xleftrightarrow.svg);
//     -webkit-mask: url(images/xleftrightarrow.svg);
// }

// .katex .doublerightarrow-mask {
//     mask: url(images/doublerightarrow.svg);
//     -webkit-mask: url(images/doublerightarrow.svg);
// }

// .katex .doubleleftarrow-mask {
//     mask: url(images/doubleleftarrow.svg);
//     -webkit-mask: url(images/doubleleftarrow.svg);
// }

// .katex .doubleleftrightarrow-mask {
//     mask: url(images/doubleleftrightarrow.svg);
//     -webkit-mask: url(images/doubleleftrightarrow.svg);
// }

// .katex .leftharpoon-mask {
//     mask: url(images/leftharpoon.svg);
//     -webkit-mask: url(images/leftharpoon.svg);
// }

// .katex .xleftharpoon-mask {
//     mask: url(images/xleftharpoon.svg);
//     -webkit-mask: url(images/xleftharpoon.svg);
// }

// .katex .rightharpoon-mask {
//     mask: url(images/rightharpoon.svg);
//     -webkit-mask: url(images/rightharpoon.svg);
// }

// .katex .xrightharpoon-mask {
//     mask: url(images/xrightharpoon.svg);
//     -webkit-mask: url(images/xrightharpoon.svg);
// }

// .katex .hookleftarrow-mask {
//     mask: url(images/hookleftarrow.svg);
//     -webkit-mask: url(images/hookleftarrow.svg);
// }

// .katex .hookrightarrow-mask {
//     mask: url(images/hookrightarrow.svg);
//     -webkit-mask: url(images/hookrightarrow.svg);
// }

// .katex .mapsto-mask {
//     mask: url(images/mapsto.svg);
//     -webkit-mask: url(images/mapsto.svg);
// }

// .katex .leftharpoondown-mask {
//     mask: url(images/leftharpoondown.svg);
//     -webkit-mask: url(images/leftharpoondown.svg);
// }

// .katex .xleftharpoondown-mask {
//     mask: url(images/xleftharpoondown.svg);
//     -webkit-mask: url(images/xleftharpoondown.svg);
// }

// .katex .rightharpoondown-mask {
//     mask: url(images/rightharpoondown.svg);
//     -webkit-mask: url(images/rightharpoondown.svg);
// }

// .katex .xrightharpoondown-mask {
//     mask: url(images/xrightharpoondown.svg);
//     -webkit-mask: url(images/xrightharpoondown.svg);
// }

// .katex .rightleftharpoons-mask {
//     mask: url(images/rightleftharpoons.svg);
//     -webkit-mask: url(images/rightleftharpoons.svg);
// }

// .katex .leftrightharpoons-mask {
//     mask: url(images/leftrightharpoons.svg);
//     -webkit-mask: url(images/leftrightharpoons.svg);
// }

// .katex .overgroup-mask {
//     mask: url(images/overgroup.svg);
//     -webkit-mask: url(images/overgroup.svg);
// }

// .katex .undergroup-mask {
//     mask: url(images/undergroup.svg);
//     -webkit-mask: url(images/undergroup.svg);
// }

// .katex .twoheadleftarrow-mask {
//     mask: url(images/twoheadleftarrow.svg);
//     -webkit-mask: url(images/twoheadleftarrow.svg);
// }

// .katex .twoheadrightarrow-mask {
//     mask: url(images/twoheadrightarrow.svg);
//     -webkit-mask: url(images/twoheadrightarrow.svg);
// }

// .katex .linesegment-mask {
//     mask: url(images/linesegment.svg);
//     -webkit-mask: url(images/linesegment.svg);
// }

// .katex .longequal-mask {
//     mask: url(images/longequal.svg);
//     -webkit-mask: url(images/longequal.svg);
// }

// .katex .tofrom-mask {
//     mask: url(images/tofrom.svg);
//     -webkit-mask: url(images/tofrom.svg);
// }

// .katex .leftrightarrows-mask {
//     mask: url(images/leftrightarrows.svg);
//     -webkit-mask: url(images/leftrightarrows.svg);
// }