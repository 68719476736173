// stylelint-disable at-rule-no-vendor-prefix, declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css


// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Change the default tap highlight to be completely transparent in iOS.

*,
*::before,
*::after {
    box-sizing: border-box; // 1
}

html {
    font-size: .625em;
    -webkit-text-size-adjust: 100%; // 4
    -webkit-tap-highlight-color: rgba($black, 0); // 5
}


// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Set an explicit initial text-align value so that we can later use
//    the `inherit` value on things like `<th>` elements.

body {
    margin: 0; // 1
    text-align: left; // 3
    background-color: $white; // 2
}

// Future-proof rule: in browsers that support :focus-visible, suppress the focus outline
// on elements that programmatically receive focus but wouldn't normally show a visible
// focus outline. In general, this would mean that the outline is only applied if the
// interaction that led to the element receiving programmatic focus was a keyboard interaction,
// or the browser has somehow determined that the user is primarily a keyboard user and/or
// wants focus outlines to always be presented.
//
// See https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
// and https://developer.paciellogroup.com/blog/2018/03/focus-visible-and-backwards-compatibility/
[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
}


// Content grouping
//
// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.

hr {
    box-sizing: content-box; // 1
    height: 0; // 1
    overflow: visible; // 2
}


//
// Typography
//

// Remove top margins from headings
//
// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.
// stylelint-disable-next-line selector-list-comma-newline-after
h1,
h2 {
    margin: 3rem 0;

    &:first-child {
        margin-top: 0;
    }
}

h3,
h4,
h5,
h6,
p {
    margin: 1rem 0;
}

ul,
dl {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul ul {
    margin-bottom: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder; // Add the correct font weight in Chrome, Edge, and Safari
}

small {
    font-size: $font-size-small-em;
}

//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
//

sub,
sup {
    position: relative;
    font-size: $font-size-small-em;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}



// And undo these styles for placeholder links/named anchors (without href).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}


//
// Code
//


pre {
    font-family: "Courier New", monospace;
    ;
    // Remove browser default top margin
    margin-top: 0;
    // Reset browser default of `1em` to use `rem`s
    margin-bottom: 1rem;
    // Don't allow content to break outside
    overflow: auto;
    // Disable auto-hiding scrollbar in IE & legacy Edge to avoid overlap,
    // making it impossible to interact with the content
    -ms-overflow-style: scrollbar;
    white-space: pre-wrap;
}

//
// Images and content
//

img {
    vertical-align: middle;
    border-style: none; // Remove the border on images inside links in IE 10-.
    max-width: 100%;
}

svg {
    // Workaround for the SVG overflow bug in IE10/11 is still required.
    // See https://github.com/twbs/bootstrap/issues/26878
    overflow: hidden;
    vertical-align: middle;
}


//
// Tables
//

table {
    border-collapse: collapse; // Prevent double borders
}

// 1. Removes font-weight bold by inheriting
// 2. Matches default `<td>` alignment by inheriting `text-align`.
// 3. Fix alignment for Safari

th {
    font-weight: bold; // 1
    text-align: inherit; // 2
    text-align: -webkit-match-parent; // 3
}


//
// Forms
//

label {
    display: inline-block;
}

// Remove the default `border-radius` that macOS Chrome adds.
//
// Details at https://github.com/twbs/bootstrap/issues/24093
button {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 0;
}

// Work around a Firefox/IE bug where the transparent `button` background
// results in a loss of the default `button` focus styles.
//
// Credit: https://github.com/suitcss/base/
button:focus {
    outline: none;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0; // Remove the margin in Firefox and Safari
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible; // Show the overflow in Edge
}

button,
select {
    text-transform: none; // Remove the inheritance of text transform in Firefox
}

// Set the cursor for non-`<button>` buttons
//
// Details at https://github.com/twbs/bootstrap/pull/30562
[role="button"] {
    cursor: pointer;
}

// Remove the inheritance of word-wrap in Safari.
//
// Details at https://github.com/twbs/bootstrap/issues/24990
select {
    word-wrap: normal;
}


// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
[type="button"],
// 1
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; // 2
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="submit"]:focus {
    outline: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box; // 1. Add the correct box sizing in IE 10-
    padding: 0; // 2. Remove the padding in IE 10-
    background-color: $secondary;
    border-color: $secondary;
}


textarea {
    overflow: auto; // Remove the default vertical scrollbar in IE.
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    resize: vertical;
}

fieldset {
    // Browsers set a default `min-width: min-content;` on fieldsets,
    // unlike e.g. `<div>`s, which have `min-width: 0;` by default.
    // So we reset that to ensure fieldsets behave more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359
    // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
    min-width: 0;
    // Reset the default outline behavior of fieldsets so they don't affect page layout.
    padding: 0;
    margin: 0;
    border: 0;
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    outline-offset: -2px; // 2. Correct the outline style in Safari.
    -webkit-appearance: none;
}

//
// Remove the inner padding in Chrome and Safari on macOS.
//

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

//
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
//

::-webkit-file-upload-button {
    font: inherit; // 2
    -webkit-appearance: button; // 1
}